let initHasBeenCalled = false;
let hasStartedLoad = false;

let appliedParams = null;

function parseInitHtml(html) {
  const newString = (html || '').trim();
  try {
    console.log('Parsing init html', newString)
    return JSON.parse(newString);
  } catch(e) {
    console.error('Failed to parse init html', e);
    return null;
  }
}

function init () {
  hasStartedLoad = true;
  const urlParams = new URLSearchParams(window.location.search);

  let cnf = Object.assign({
    apiBase: 'https://api.gamifiera.com',
    cdnBase: 'https://cdn.gamifiera.com',
    widgetBase: 'https://cdn.gamifiera.com/static/gmf',
    // merchantId: null,
    // overrideVersion: 'dev',
  }, appliedParams);

  if (urlParams.has('gmf_local_dev')) {
    cnf.widgetBase = 'https://localhost:8081';
    cnf.overrideVersion = 'dev';
  }

  if (urlParams.has('gmf_version')) {
    cnf.overrideVersion = urlParams.get('gmf_version');
  }

  if (urlParams.has('gmf_beta_version')) {
    cnf.widgetBase = 'https://cdn.gamifiera.com/static/gmf-beta';
    cnf.overrideVersion = urlParams.get('gmf_beta_version');
  }

  cnf.previewConfig = getPreviewConfig();
  
  const initHook = document.getElementById('gmf-loader-config');

  if (initHook) {
    const settings = parseInitHtml(initHook.innerHTML);

    if (settings) {
      cnf = { ...cnf, ...settings };
    }
  }

  if (cnf.previewConfig && typeof cnf.previewConfig === 'string' && cnf.previewConfig.startsWith('{') && cnf.previewConfig.endsWith('}')) {
    cnf.previewConfig = { ...unfoldConfig(JSON.parse(cnf.previewConfig)), ...getPreviewConfig() };
  }

  window.gmf_config = cnf;

  var x = new XMLHttpRequest();
  x.open('GET', cnf.apiBase + '/v1/merchant-config', true);
  x.setRequestHeader('X-GMF-Merchant-Id', cnf.merchantId);

  function onLoadFail() {
    console.error('Gamifiera SDK failed to load merchant config', x.statusText);
  }

  x.onload = function () {
    if (x.readyState === 4) {
      if (x.status === 200) {
        var merchantConfig = JSON.parse(x.responseText);
        if (cnf.previewConfig) {
          merchantConfig = mergeDeep(merchantConfig, cnf.previewConfig);
        }
        cnf.merchantConfig = merchantConfig;

        var version = cnf.overrideVersion || merchantConfig.widgets.version;
        var widgetPath = cnf.widgetBase + "/" + version + "/";

        var s = document.createElement("script");
        s.type = "text/javascript";
        s.src = widgetPath + 'gmf.js';
        s.charset = 'UTF-8';
        s.async = true;
        document.head.appendChild(s);

        var c = document.createElement("link");
        c.rel = "stylesheet";
        c.href = widgetPath + 'base.css';
        c.charset = 'UTF-8';
        document.head.appendChild(c);
      } else {
        onLoadFail();
      }
    }
  };
  x.onerror = onLoadFail;
  x.send(null);
}

function loaderGmfHandler (action, ...params) {
  if (action === 'init' && ! hasStartedLoad) {
    if (appliedParams) {
      console.warn('GMF init was called multiple times before load. This is not supported.');
    }
    appliedParams = params[0];
    if (! initHasBeenCalled) {
      initHasBeenCalled = true;
      setTimeout(init, 0);
    }
  } else {
    window._gmf.push([action, ...params]);
  }
}

if (window._gmf_loader_initialized) {
  console.error('GMF loader script was loaded multiple times. This is not supported.');
} else {
  window._gmf_loader_initialized = true;

  const previousGmf = window._gmf;
  window._gmf = [];

  window.gmf = loaderGmfHandler;

  if (previousGmf) {
    for (let i = 0; i < previousGmf.length; ++i) {
      const [action, ...params] = previousGmf[i];
      loaderGmfHandler(action, ...params);
    }
  }
}

// Helper functions

function isObject(item) {
  return (item && typeof item === 'object' && !Array.isArray(item));
}

function mergeDeep(target, source) {
  let output = JSON.parse(JSON.stringify(target));
  if (isObject(target) && isObject(source)) {
    Object.keys(source).forEach(key => {
      if (isObject(source[key])) {
        if (!(key in target))
          Object.assign(output, { [key]: source[key] });
        else
          output[key] = mergeDeep(target[key], source[key]);
      } else {
        Object.assign(output, { [key]: source[key] });
      }
    });
  }
  return output;
}


function unfoldConfig (flat) {
  var nested = {};

  for (var key in flat) {
    var keyparts = key.split('.');
    let lastKey = keyparts.pop();
    let n = nested;
    for (var part of keyparts) {
      if (! (part in n)) {
        n[part] = {};
      }
      n = n[part];
    }
    n[lastKey] = flat[key];
  }

  return nested;
}

function getPreviewConfig () {
  try {
    var prefix = 'gmf-preview-conf=';
    var hashparts = window.location.hash.split('#');
    for (var part of hashparts) {
      if (part.indexOf(prefix) === 0) {
        var b64 = part.substring(prefix.length);
        var json = atob(b64);
        var data = unfoldConfig(JSON.parse(json));
        console.log('Applying preview config', data);
        return data;
      }
    }
  } catch (e) {
    console.error('Preview config error', e);
  }
  return null;
}
